import { RichTextSchema } from "@storyblok/react"
import { Script, WrapPageElementNodeArgs } from "gatsby"
import { storyblokInit, apiPlugin } from "gatsby-source-storyblok"
import _cloneDeep from "lodash/cloneDeep"
import React, { Suspense } from "react"

import Splash from "./components/elements/V2/Splash"
import Layout from "./components/layout"
import componentsV2 from "./storyblok/componentsV2"
import { storyblokUrlToInternalUrl } from "./utils/storyblok"

const CartPreviewModal = React.lazy(
  () => import("./components/StoryBlok/Modals/CartPreviewModal")
)

// TODO: Remove loadable components package and use webpack to split storyblok components into their own bundles

// Storyblok Rich text configuration

const sbRichTextSchema = _cloneDeep(RichTextSchema)

const isEmailLinkType = (type: string) => type === "email"

sbRichTextSchema.marks.link = function (node: any) {
  const attrs = { ...node.attrs }
  const { linktype = "url" } = node.attrs

  if (isEmailLinkType(linktype)) {
    attrs.href = `mailto:${attrs.href}`
  }

  if (
    attrs.href &&
    !attrs.href.includes("http") &&
    !attrs.href.includes("#app-modal") &&
    !attrs.href.includes("mailto:")
  ) {
    attrs.href = `${
      process.env.GATSBY_PREFIX_PATH || ""
    }${storyblokUrlToInternalUrl(attrs.href)}/`
  }

  if (attrs.anchor) {
    attrs.href = `${attrs.href}#${attrs.anchor}`
    delete attrs.anchor
  }

  return {
    tag: [
      {
        tag: "a",
        attrs: attrs,
      },
    ],
  }
}

storyblokInit({
  accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
  apiOptions: {},
  bridge: process.env.GATSBY_STORYBLOK_LOAD_BRIDGE === "true",
  use: [apiPlugin],
  components: componentsV2,
  richText: {
    schema: sbRichTextSchema,
  },
})

const WrapPageElement = ({ element }: WrapPageElementNodeArgs) => {
  return (
    <>
      <Splash />

      <Layout>
        <Script src="/ytp.min.js" /> {/* built by npm script "uglifyjs" */}
        {element}
      </Layout>

      {/* TODO: Identify if there's refactor opportunity to use a proper fallback and move the lazy loading in children / parent components */}
      <Suspense fallback={null}>
        <CartPreviewModal />
      </Suspense>
    </>
  )
}

export default WrapPageElement
