// TODO: consolidate this component and InputError into variants of a single component
import classNames from "classnames"
import React from "react"

import { Color } from "../../../../constants/V2/color"
import CircleExclamationIcon from "../Icons/CircleExclamationIcon"
import Typography from "../Typography"

interface Props {
  error: string
  className?: string
}

const InputInstructions = ({ error, className, ...props }: Props) => {
  return (
    <div className="mt-[5px] flex gap-[5px] text-center">
      <CircleExclamationIcon className="text-charcoal-v2 opacity-70" />
      <Typography
        color={Color.Charcoal}
        text={error}
        font="grotesk"
        size="body-sm"
        weight="book"
        className={classNames("pt-[1px] opacity-70", className)}
        {...props}
      />
    </div>
  )
}

export default InputInstructions
