import classNames from "classnames"
import React from "react"

type Props = {
  children: React.ReactNode
  isVisible?: boolean
}

const StickyContainer = ({ children, isVisible }: Props) => {
  return (
    <div
      className={classNames(
        {
          "h-[calc(70px+env(safe-area-inset-bottom,0px))] sm-v2:h-0": isVisible,
        },
        "fixed bottom-0 left-0 z-30 h-0 w-full bg-white transition-all duration-300"
      )}
    >
      <div
        className={classNames(
          "col-span-full flex items-center px-20-v2 py-[calc(10px+env(safe-area-inset-bottom,0px))]"
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default StickyContainer
