import { RichTextSchema } from "@storyblok/react"
import classNames from "classnames"
import { navigate } from "gatsby"
import { renderRichText } from "gatsby-source-storyblok"
import _cloneDeep from "lodash/cloneDeep"
import React, { useEffect, useMemo, useRef } from "react"
import { renderToString } from "react-dom/server"

import InteractiveParagraphDropdown from "./InteractiveParagraphDropdown"
import { Color } from "../../../../constants/V2/color"
import Typography from "../../../elements/V2/Typography"
import LayoutSection from "../../LayoutComponents/LayoutSection"

import { getUrlFromStoryblokLink } from "@utils/storyblok"
import { getTextColorClass } from "@utils/V2/color"

const interactiveParagraphSchema = _cloneDeep(RichTextSchema)

interactiveParagraphSchema.nodes.paragraph = () => {
  return {
    tag: "span",
  }
}

const getResolver =
  (mode: "light" | "dark") => (component: string, blok: any) => {
    switch (component) {
      case "interactiveParagraphDropdown":
        return renderToString(
          <InteractiveParagraphDropdown
            mode={mode}
            icon={blok.icon}
            dropdownLinkText={blok.dropdownLinkText}
            dropdownLinkUrl={getUrlFromStoryblokLink(blok.dropdownLinkUrl)}
            dropdownText={blok.dropdownText}
            text={blok.text}
            id={blok._uid}
          />
        )
      default:
        return `<h1>Resolver not defined</h1>`
    }
  }

type Props = {
  headerText?: string
  mode: "light" | "dark"
  content: Storyblok.StoryblokRichtextContent
}

// This component is made to be compatible with Storyblok rich text
const InteractiveParagraph = ({
  headerText,
  mode,
  content,
  ...props
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const renderedRichText = useMemo(
    () => ({
      __html: renderRichText(content, {
        schema: interactiveParagraphSchema,
        resolver: getResolver(mode),
      }),
    }),
    [mode, content]
  )

  useEffect(() => {
    // Link handlers
    const linkElements = wrapperRef.current?.getElementsByTagName("a")

    const onLinkClickListenerMap = new Map<
      HTMLAnchorElement,
      (e: MouseEvent) => void
    >()

    const createOnLinkClickListener = (
      e: MouseEvent,
      linkElement: HTMLAnchorElement
    ) => {
      if (e.currentTarget && linkElements) {
        e.preventDefault()
        const href = linkElement.href.replace(/^https?:\/\/[^/]+/, "")
        navigate(href)
      }
    }

    /**
     * Add event listeners on link click event to handle push state
     * with client-side router instead of full page navigate
     */
    if (linkElements) {
      for (let i = 0; i < linkElements.length; i++) {
        const linkElement = linkElements[i]
        const onLinkClickListener = (e: MouseEvent) =>
          createOnLinkClickListener(e, linkElement)
        onLinkClickListenerMap.set(linkElement, onLinkClickListener)
        linkElement.addEventListener("click", onLinkClickListener)
      }
    }

    // Checkbox handlers
    const checkboxElements = wrapperRef.current?.getElementsByTagName("input")

    const onCheckboxChecked = (e: any) => {
      if (e.currentTarget?.checked && checkboxElements) {
        for (let i = 0; i < checkboxElements.length; i++) {
          if (checkboxElements[i] !== e.currentTarget) {
            checkboxElements[i].checked = false
          }
        }
      }
    }

    /**
     * Add event listeners on dropdown checkbox checked event that will uncheck
     * other dropdown checkboxes so that only 1 dropdown is open at any given stage
     */
    if (checkboxElements) {
      for (let i = 0; i < checkboxElements.length; i++) {
        checkboxElements[i].addEventListener("change", onCheckboxChecked)
      }
    }

    return () => {
      // Remove listeners for checkbox
      if (checkboxElements) {
        for (let i = 0; i < checkboxElements.length; i++) {
          checkboxElements[i].removeEventListener("change", onCheckboxChecked)
        }
      }
      // Remove listeners for link
      if (linkElements) {
        for (let i = 0; i < linkElements?.length; i++) {
          const linkElement = linkElements[i]
          const onLinkClickListener = onLinkClickListenerMap.get(linkElement)
          if (onLinkClickListener) {
            linkElements[i].removeEventListener("click", onLinkClickListener)
            onLinkClickListenerMap.delete(linkElement)
          }
        }
      }
    }
  }, [renderedRichText])

  return (
    <LayoutSection
      primaryBackgroundColor={mode === "light" ? Color.White : Color.Black}
      {...props}
    >
      {headerText ? (
        <Typography
          className="mb-lg-v2"
          weight="medium"
          color={mode === "light" ? Color.Charcoal : Color.White}
          font="grotesk"
          size="h3"
          text={headerText}
          element="h3"
        />
      ) : null}

      <div
        ref={wrapperRef}
        className={classNames(
          "text-responsive-lead-lg-v2 !leading-[2.5]",
          mode === "light"
            ? getTextColorClass(Color.Charcoal)
            : getTextColorClass(Color.White)
        )}
        dangerouslySetInnerHTML={renderedRichText}
      />
    </LayoutSection>
  )
}

export default InteractiveParagraph
